import React from 'react';
import { Container, Carousel, Image } from 'react-bootstrap';

const PictureGallerySection = () => {
  return (
    <section id="gallery" className="mt-4">
    <Container>
      <Carousel>
        <Carousel.Item>
          <Image
            className="d-block mx-auto"
            src="https://lh3.googleusercontent.com/p/AF1QipNo7NyigSd6OdbFfPhsZpPCvX_QvihUN7k3pzyx=w960-h960-n-o-v1"
            alt="First slide"
            style={{ maxWidth: '400px', maxHeight: '400px' }} // Adjust dimensions here
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block mx-auto"
            src="https://lh3.googleusercontent.com/p/AF1QipNpAnF2AnbI240ZtUoAf-eZPOsF9Qz1GQwBncij=w960-h960-n-o-v1"
            alt="Second slide"
            style={{ maxWidth: '400px', maxHeight: '400px' }} // Adjust dimensions here
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block mx-auto"
            src="https://lh3.googleusercontent.com/p/AF1QipNKfKAgUumnxa7WPCxJtrjMcsIwlXR2kNg7ByvV=w960-h960-n-o-v1"
            alt="Third slide"
            style={{ maxWidth: '400px', maxHeight: '400px' }} // Adjust dimensions here
          />
        </Carousel.Item>
        {/* Add more Carousel.Items with additional images as needed */}
      </Carousel>
    </Container>
  </section>
  );
};

export default PictureGallerySection;