import React from 'react';
import { Container, Col, Row, } from 'react-bootstrap';
import { FaIndustry, FaBuilding, FaHome, FaClipboardCheck, FaSolarPanel } from 'react-icons/fa';

const AboutSection = () => {
  return (
    <section id="about" className="mt-5">
        <Container>
            <Row>
                <Col md={6} lg={4}>
                <div className="feature">
                    <FaIndustry className="feature-icon" />
                    <h3>Industrial</h3>
                    <p>We specialize in electrical installations for industrial setups, ensuring reliability and safety.</p>
                </div>
                </Col>
                <Col md={6} lg={4}>
                <div className="feature">
                    <FaBuilding className="feature-icon" />
                    <h3>Commercial</h3>
                    <p>Our expertise extends to commercial installations, supporting businesses with their electrical needs.</p>
                </div>
                </Col>
                <Col md={6} lg={4}>
                <div className="feature">
                    <FaHome className="feature-icon" />
                    <h3>Domestic</h3>
                    <p>We offer tailored electrical solutions for residential properties, enhancing your living spaces.</p>
                </div>
                </Col>
                <Col md={6} lg={4}>
                <div className="feature">
                    <FaClipboardCheck className="feature-icon" />
                    <h3>Inspection and Testing</h3>
                    <p>Our comprehensive inspection and testing services ensure safety and compliance with regulations.</p>
                </div>
                </Col>
                <Col md={6} lg={4}>
                <div className="feature">
                    <FaSolarPanel className="feature-icon" />
                    <h3>SolarPV & Battery Storage</h3>
                    <p>We provide sustainable energy solutions through solar panels and battery storage systems.</p>
                </div>
                </Col>
            </Row>
        </Container>
    </section>
  );
};

export default AboutSection;