import React from 'react';
import { Container, Col, Row, } from 'react-bootstrap';
import { FaPhone, FaClock } from 'react-icons/fa';

const ContactSection = () => {
  return (
    <section id="contact" className="mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="p-4">
              <h4 className="mb-4">Contact Information</h4>
              <div className="contact-item d-flex flex-column align-items-start">              
              <h5 className="mb-0" style={{ marginRight: '8px' }} >Ben</h5>
                <div className="d-flex align-items-center">                  
                  <FaPhone className="mr-2" style={{ fontSize: '1em', marginRight: '8px' }} />
                  <a href="tel:07854 826914" style={{ color: 'black' }}>07969 087406</a>                
                </div>
              </div>
              <div className="contact-item d-flex flex-column align-items-start">
              <h5 className="mb-0" style={{ marginRight: '8px' }}>Ian</h5>               
                <div className="d-flex align-items-center">
                  <FaPhone className="mr-2" style={{ fontSize: '1em', marginRight: '8px' }} />
                  <a href="tel:07854 826914" style={{ color: 'black' }}>07854 826914</a>                             
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="p-4">
              <h4 className="mb-4">Business Hours</h4>
              <div className="contact-item d-flex flex-column align-items-start">
                <h5 className="mb-0" >Monday to Friday</h5>
                <div className="d-flex align-items-center">
                  <FaClock className="mr-2" style={{ fontSize: '1em', marginRight: '8px' }} />
                  <p className="mb-0">7:30 AM - 5:00 PM</p>
                </div>              
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection;