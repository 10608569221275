import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const NavigationNavbar = () => {
  return (
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link href="#about">
              About
            </Nav.Link>
            <Nav.Link href="#gallery">
              Gallery
            </Nav.Link>
            <Nav.Link href="#contact">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
};

export default NavigationNavbar;