import React from 'react';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Container, Navbar } from 'react-bootstrap';

const ContactNavbar = () => {
  return (
    <Navbar expand="lg" bg="light" variant="light">
        <Container>
            <Navbar.Text className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                <p className="mb-2 mb-sm-0">
                    <BsFillTelephoneFill style={{ fontSize: '1em', marginRight: '8px' }} />
                    <a href="tel:07969 087406" style={{ paddingRight: '12px' }}>
                      07969 087406
                    </a>
                    <BsFillTelephoneFill style={{ fontSize: '1em', marginRight: '8px' }} />
                    <a href="tel:07854 826914" style={{ paddingRight: '12px' }}>
                      07854 826914
                    </a>
                </p>
                <p className="mb-2 mb-sm-0">
                    <MdEmail style={{ fontSize: '1em', marginRight: '8px' }} />
                    <a
                    href="mailto:moonwoodelectrical@gmail.com"
                    style={{ paddingRight: '12px' }}
                    >
                    moonwoodelectrical@gmail.com
                    </a>
                </p>
            </Navbar.Text>
        </Container>
    </Navbar>
  );
};

export default ContactNavbar;