import React from 'react';
import { Container } from 'react-bootstrap';
import ContactNavbar from './ContactNavbar';
import BrandNavbar from './BrandNavbar'
import NavigationNavbar from './NavigationNavbar'
import AboutSection from './AboutSection';
import PictureGallerySection from './PictureGallerySection';
import ContactSection from './ContactSection';
import { FaInstagram } from 'react-icons/fa';


function App() {
  return (
    <>
      <ContactNavbar />
      <NavigationNavbar />
      <BrandNavbar/>

      {/* Main content */}
      <Container className="mt-4">
        <AboutSection/>
        <PictureGallerySection/>
        <ContactSection/>
      </Container>

      {/* Footer */}
      <footer className="bg-dark text-white text-center mt-4 py-3">
        &copy; {new Date().getFullYear()} Moonwood Electrical. All rights reserved.
        <p className="mb-2 pl-0 pl-sm-5">
            <a
            href="https://www.instagram.com/moonwood_electrical/"
            target="_blank"
            rel="noopener noreferrer"
            >
            <FaInstagram size={24} />
            </a>
        </p>
      </footer>
    </>
  );
}

export default App;
